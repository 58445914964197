export {};

declare global {
  interface Array<T> {
    groupBy<K extends keyof T>(key: K): { [x: string]: T[] };
  }
}

Array.prototype.groupBy = function <T, K extends keyof T>(this: T[], key: K) {
  return this.reduce<{ [x: string]: T[] }>(function (acc, currentValue) {
    if (!acc[`${currentValue[key]}`]) {
      acc[`${currentValue[key]}`] = [];
    }
    acc[`${currentValue[key]}`].push(currentValue);
    return acc;
  }, {} as { [x: string]: T[] });
};
